<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12 table-responsive">
        <table class="table table-bordered table-striped table-hover text-nowrap table-sm">
          <thead>
            <tr class="text-center">
              <th>Acción</th>
              <th>Tipo Vehículo</th>
              <th>Tipo Operación</th>
              <th>Turno</th>
              <th>Vehículo</th>
              <th>Conductor</th>
              <th
                v-if="
                  postulaciones[0] &&
                  postulaciones[0].tipo_operacion != 3 &&
                  postulaciones[0].turno.dia_turno[0].cant_horas > 12
                "
              >Conductor 2</th>
              <th>Fecha</th>
            </tr>
          </thead>
          <tbody
            v-for="(postu, index) in content"
            :key="index"
          >
            <tr>
              <td>
                <div 
                  class="btn-group"
                  v-if="postu.estado == 1"
                >
                  <button
                    class="btn btn-default btn-sm bg-navy"
                    v-if="$route.params.accion == 1 &&
                      $store.getters.can('tep.postulaciones.editRestDay') &&
                      postu.fecha >= hoy &&
                      selectVehi != postu.indexPost + '_' + postu.indexDetPost
                    "
                    @click="editDetPost(postu.indexPost, postu.indexDetPost)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-default btn-sm bg-primary"
                    v-if="
                      form1[index].fecha >= hoy &&
                      selectVehi == postu.indexPost + '_' + postu.indexDetPost
                    "
                    @click="saveDetPost(index, postu.tipo_operacion_id)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <!-- Se comentarea por que no se debe elimminar este tipo de asignaciones -->
                  <!-- <button
                    class="btn btn-default btn-sm bg-danger"
                    v-if="
                      $route.params.accion == 1 &&
                      $store.getters.can('tep.postulaciones.delete') &&
                      form1[index].fecha >= hoy &&
                      selectVehi != postu.indexPost + '_' + postu.indexDetPost
                    "
                    @click="deleteDetPost(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button> -->
                </div>
              </td>
              <td>{{ postu.tipo_vehiculo }}</td>
              <td>
                {{ postu.tipo_operacion }}
                <strong>{{ postu.area ? `(${postu.area})` : ""}}</strong>
              </td>
              <td>{{ postu.turno }}</td>
              <td>
                <input type="text" class="form-control form-control-sm" label="placa" 
                  v-model="form1[index].vehiculo.placa"
                  v-if="form1[index].vehiculo"
                  :id="`slct_vehiculo_u${postu.indexPost} ${postu.indexDetPost}`"
                  :disabled="selectVehi != postu.indexPost + '_' + postu.indexDetPost"
                  @change="buscarVehiculos(index, postu.tipo_vehiculo_id)"
                />
              </td>
              <td>
                <input type="text" class="form-control form-control-sm" label="numero_documento" 
                  v-model="form1[index].conductor.numero_documento"
                  v-if="form1[index].conductor"
                  :id="`slct_conductor_u${postu.indexPost} ${postu.indexDetPost}`"
                  :disabled="selectVehi != postu.indexPost + '_' + postu.indexDetPost"
                  @change="buscarConductores(index)"
                />
                <div>
                  <small>{{form1[index].conductor ? form1[index].conductor.nombre : ""}}</small>
                </div>
              </td>
              <td
                v-if="
                  postulaciones[0] &&
                  postulaciones[0].tipo_operacion != 3 &&
                  postulaciones[0].turno.dia_turno[0].cant_horas > 12
                "
              >
                <input type="text" class="form-control form-control-sm" label="numero_documento" 
                  v-model="form1[index].conductor_2.numero_documento"
                  v-if="form1[index].conductor_2"
                  :id="`slct_conductor_2_u${postu.indexPost} ${postu.indexDetPost}`"
                  :disabled="selectVehi != postu.indexPost + '_' + postu.indexDetPost"
                  @change="buscarConductores_2(index)"
                />
                <div>
                  <small>{{form1[index].conductor_2 ? form1[index].conductor_2.nombre : ""}}</small>
                </div>
              </td>
              <td>
                <input type="text" class="form-control form-control-sm" label="fecha" 
                  v-model="form1[index].fecha_ver"
                  v-if="form1[index].fecha_ver"
                  disabled
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import "moment/locale/es";
import $ from "jquery";

export default {
  name: "PostulacionCambio",
  components: {},
  data() {
    return {
      cargando: false,
      form: [],
      form1: [],
      content: [],
      params: {},
      nominacion_id: null,
      empresa_id: null,
      postulaciones: [],
      slctVehi: null,
      listasForms: {
        slct_vehiculo: {},
        slct_conductor: {},
        slct_conductor_2: {},
      },
      vehiculos: [],
      conductores: [],
      conductores_2: [],
      diasPost: null,
      hoy: moment().format("YYYY-MM-DD"),
      postEmpresa: [],
      moment: moment,
      selectVehi: null,
    };
  },

  async beforeMount() {
    await this.init();
  },

  computed: {
    ...mapState("modTep", ["postulacionTepShow", "loading"]),

    time: function () {
      return this.date.format("mm:ss");
    },
  },

  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modTep", [
      "actGetPostulacionesShow",
      "actSavePostulacion",
      "actSavePostulacionDet",
      "actEditPostulacion",
      "actDeletePostulacion",
    ]),

    async init() {
      this.$parent.cargando = true;
      this.selectVehi = null;

      this.postulaciones = await this.$parent.cambios;
      var fecha1 = await moment(this.postulaciones[0].tep_nominacion.fecha_ini);
      var fecha2 = await moment(this.postulaciones[0].tep_nominacion.fecha_fin);
      this.diasPost = (await fecha2.diff(fecha1, "days")) + 1;
      await this.buildContent();
      this.$parent.cargando = false;
    },

    async buildContent() {
      let arrayForm = [];
      let arrayContent = [];
      this.postulaciones.forEach((postu, i) => {
        postu.tep_det_postulacion.forEach((tep_det_post, j) => {
          let conductor = null;
          if (tep_det_post.conductor_id && tep_det_post.conductor) {
            conductor = {
              id: tep_det_post.conductor_id,
              numero_documento: tep_det_post.conductor.numero_documento,
              nombre: `${tep_det_post.conductor.nombres} ${tep_det_post.conductor.apellidos}`,
            };
          }
          let conductor_2 = null;
          if (tep_det_post.conductor_2_id && tep_det_post.conductor2) {
            conductor_2 = {
              id: tep_det_post.conductor_2_id,
              numero_documento: tep_det_post.conductor2.numero_documento,
              nombre: `${tep_det_post.conductor2.nombres} ${tep_det_post.conductor2.apellidos}`,
            };
            if (this.$route.params.accion == 2) {
              $("#slct_conductor_2" + i + "_" + j).prop("disabled", true);
            }
          }

          // Se llena el contenido para el formulario
          arrayForm.push({
            id: tep_det_post.id,
            tep_nominacion: postu.tep_nominacion_id,
            tep_det_nominacion: postu.id,
            vehiculo: {
              id: tep_det_post.vehiculo_id,
              placa: tep_det_post.vehiculo.placa,
            },
            conductor,
            conductor_2,
            fecha_ver: moment(tep_det_post.fecha).format("dddd, LL"),
            fecha: tep_det_post.fecha,
            indice_operacion: i,
            indice_veh: j,
            pos_dia: tep_det_post.pos_dia,
            estado: 2,
          });

          // Se llena el contenido para la tabla de la vista
          arrayContent.push({
            tipo_vehiculo: postu.tep_tipos_vehiculos.nombre,
            tipo_vehiculo_id: postu.tipo_vehiculo,
            tipo_operacion_id: postu.tipo_operacion,
            tipo_operacion: postu.nTipoOperacion,
            area: postu.nArea,
            turno: postu.turno.nombre,
            fecha: moment(tep_det_post.fecha).format("YYYY-MM-DD"),
            indexPost: i,
            indexDetPost: j,
            estado: tep_det_post.estado,
          });
        });
      });

      this.form1 = arrayForm;
      this.content = arrayContent;
    },

    editDetPost(indexPost, indexDetPost) {
      this.selectVehi = indexPost + "_" + indexDetPost;
    },

    async saveDetPost(index, tipoOperacion) {
      this.$parent.cargando = true;
      let formEnvio = [];
      formEnvio.push(await this.form1[index]);
      if ( (tipoOperacion != 3 && this.validarForm(index, formEnvio, tipoOperacion)) || tipoOperacion == 3) {
        let datos = { datos: this.form1[index] };
        await this.actSavePostulacionDet(datos).then((response) => {
          this.$parent.cargando = false;
          if (response.msg) {
            this.$swal({
              icon: "success",
              title: response.msg,
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
            this.selectVehi = null;
            this.content[index].estado = null;
          }
        });
        await this.$parent.init();
        await this.init();
      } else {
        this.$parent.cargando = false;
      }
    },

    async deleteDetPost(index) {
      this.$swal({
        title: "Está seguro de eliminar este detalle de la postulación?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then(async (result) => {
        if (result.value) {
          this.$parent.cargando = true;
          await axios
            .delete("/api/tep/postulaciones/" + this.form1[index].id)
            .then(() => {
              this.$parent.cargando = false;
              this.$swal({
                icon: "success",
                title:
                  "Se eliminó el detalle de la postulación exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
          await this.$parent.init();
          await this.init();
        }
      });
    },

    validarDias(diaSemana, numDias) {
      if (diaSemana == 0) {
        diaSemana = 7;
      }
      let search = numDias.find((d) => d.dia_semana === diaSemana);
      if (search != undefined) {
        return true;
      }
      return false;
    },

    getHorasTurno(diaSemana, numDias) {
      if (diaSemana == 0) {
        diaSemana = 7;
      }
      let search = numDias.find((d) => d.dia_semana === diaSemana);
      if (search != undefined) {
        return search.cant_horas;
      }
      return null;
    },

    async buscarVehiculos(index, tipo_vehiculo_id) {
      this.$parent.cargando = true;
      let dato = this.form1[index].vehiculo.placa;
      let fecha = this.form1[index].fecha;

      let res = await this.validarStatus(dato, "V", fecha);
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.$parent.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            this.form1[index].vehiculo = {};
            return false;
          }
        }

        if (res.postulacion) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo excede las horas de postulacion asignadas`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        if (res.turno.length > 0) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo ya está en otro turno para la fecha $`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        if (res["info"] == false) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        if (!res["has_lineas"]) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehiculo no se encuentra en la linea de negocio Transporte especial de pasajeros`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        if (res[0].tipo_vehiculo_id != tipo_vehiculo_id) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El tipo de vehículo no coincide.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        if (!res.modelo) {
          this.form1[index].vehiculo = {};
          this.$swal({
            icon: "error",
            title: "El modelo del vehiculo no cumple con los requisitos.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          return false;
        }

        //Valida reporte satelital del vehiculo
        if (res.ultimoRegistro) {
          if (res.ultimoRegistro.error) {
            this.form1[index].vehiculo = {};
            this.$swal({
              icon: "error",
              title: `Error GPS: ${res.ultimoRegistro.error.msg}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          } else if (res.ultimoRegistro.success) {
            await this.selectAllVehi(index, res[0]);
            this.$swal({
              icon: "success",
              title: `${res.ultimoRegistro.success.msg} Distancia: ${res.ultimoRegistro.success.distance} Km`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          }
        }
      } 
      else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.form1[index].vehiculo = {};
        this.$parent.cargando = false;
        return false;
      }

      this.$swal({
        icon: "success",
        title:
          "El vehículo cumple con los requisitos para poder ser postulado...",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
      await this.selectAllVehi(index, res[0]);
      this.$parent.cargando = false;
    },

    async buscarConductores(index) {
      this.$parent.cargando = true;
      let dato = this.form1[index].conductor.numero_documento;
      let fecha = this.form1[index].fecha;

      let res = await this.validarStatus(dato, "C", fecha);
      // Se valida si existe el conductor
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.$parent.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            this.form1[index].conductor = {};
            return false;
          }
        }

        if (res[0]) {
          if (res["info"] == false) {
            this.form1[index].conductor = {};
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          if (!res["has_lineas"]) {
            this.form1[index].conductor = {};
            this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la línea de negocio Transporte especial de pasajeros`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga alguna postulación activa
          if (res.postulacion.length > 0) {
            this.form1[index].conductor = {};
            this.$swal({
              icon: "error",
              title: `El conductor ya está postulado para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga algún turno activo
          if (res.turno && res.turno.length > 0) {
            this.form1[index].conductor = {};
            this.$swal({
              icon: "error",
              title: `El conductor ya está en otro turno para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Si el conductor es válido
          this.form1[index].conductor = {
            id: res[0].id,
            numero_documento: res[0].numero_documento,
            nombre: `${res[0].nombres} ${res[0].apellidos}`,
          };
          this.$swal({
            icon: "success",
            title: "El Conductor cumple con los requisitos para poder ser postulado...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.$parent.cargando = false;
        this.form1[index].conductor = {};
        return false;
      }
      this.$parent.cargando = false;
    },

    async buscarConductores_2(index) {
      this.$parent.cargando = true;
      let dato = this.form1[index].conductor_2.numero_documento;
      let fecha = this.form1[index].fecha;

      let res = await this.validarStatus(dato, "C2", fecha);
      // Se valida si existe el conductor
      if (res && res.length != 0) {
        if (res.validacion.error) {
          this.$parent.cargando = false;
          await this.msgDocumentacion(res);
          if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) {
            this.form1[index].conductor_2 = {};
            return false;
          }
        }

        if (res[0]) {
          if (res["info"] == false) {
            this.form1[index].conductor_2 = {};
            this.$swal({
              icon: "error",
              title: `Hacen falta campos por diligenciar en el formulario de creación de Conductores`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          if (!res["has_lineas"]) {
            this.form1[index].conductor_2 = {};
            this.$swal({
              icon: "error",
              title: `El Conductor no se encuentra en la línea de negocio Transporte especial de pasajeros`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga alguna postulación activa
          if (res.postulacion.length > 0) {
            this.form1[index].conductor_2 = {};
            this.$swal({
              icon: "error",
              title: `El conductor ya está postulado para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Se valida que el conductor no tenga algún turno activo
          if (res.turno && res.turno.length > 0) {
            this.form1[index].conductor_2 = {};
            this.$swal({
              icon: "error",
              title: `El conductor ya está en otro turno para la fecha $`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
            return false;
          }

          // Si el conductor es válido
          this.form1[index].conductor_2 = {
            id: res[0].id,
            numero_documento: res[0].numero_documento,
            nombre: `${res[0].nombres} ${res[0].apellidos}`,
          };
          this.$swal({
            icon: "success",
            title: "El Conductor cumple con los requisitos para poder ser postulado...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      } else {
        this.$swal({
          icon: "error",
          title: "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.$parent.cargando = false;
        this.form1[index].conductor_2 = {};
        return false;
      }
      this.$parent.cargando = false;
    },

    validarForm(post, form, tipoOperacion) {
      let flag = true;
      if (tipoOperacion != 3) {
        if (form.length > 0) {
          form.forEach((data) => {
            let dia_turno = this.getHorasTurno(
              moment(data.fecha).day(),
              this.postulaciones[post].turno.dia_turno
            );
            // Se valida si es necesario validar los dos conductores
            if (dia_turno > 23) {
              // Se valida si todos los campos se encuentran diligenciados
              if (data.vehiculo) {
                if (!data.vehiculo.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el vehículo`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el vehículo`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor) {
                if (!data.conductor.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el conductor 1`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el conductor 1`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor_2) {
                if (!data.conductor_2.id) {
                  this.$swal({
                    icon: "error",
                    title: `Debe diligenciar el conductor 2`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              } else {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el conductor 2`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }

              if (data.conductor && data.conductor_2) {
                if (data.conductor.id === data.conductor_2.id) {
                  this.$swal({
                    icon: "error",
                    title: `Los conductores de la postulación deben ser diferentes.`,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                  });
                  flag = false;
                }
              }
            } else {
              if (!data.vehiculo || !data.conductor) {
                this.$swal({
                  icon: "error",
                  title: `Debe diligenciar el vehículo y el conductor para todas las fechas.`,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                flag = false;
              }
            }
          });
        } else {
          this.$swal({
            icon: "error",
            title: `Debe diligenciar todos los campos para realizar la postulacion.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          flag = false;
        }
      }
      return flag;
    },

    async validarStatus(dato, tipo, fecha) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/tep/postulaciones/listaConductores";
      } else if (tipo == "C2") {
        entidad = "Conductor";
        url = "api/tep/postulaciones/listaConductores";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/tep/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
        fecha: fecha,
        sitio_id: this.postulaciones[0].tep_nominacion.sitio_id,
        cant_horas: this.postulaciones[0].turno.dia_turno[0].cant_horas,
      };

      var res = null;

      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async selectAllVehi(index, vehiculo) {
      this.listasForms.slct_vehiculo = {};
      this.form1[index].vehiculo = {
        placa: vehiculo.placa,
        id: vehiculo.id,
      };
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.obligatorios.join("<br>");
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.no_verificados.join("<br>");
          }
        }
        erroHtml += "</div>";
        await this.$swal({
          html: erroHtml,
          showCancelButton: false,
          focusConfirm: true,
          icon: "error",
          title: "Aviso en documentación",
          showConfirmButton: false,
          showCloseButton: true,
          footer:
            "<small style='text-align:center'><b>Desde el 1 de octubre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
        });
      }
    },

    back() {
      return this.$router.replace("/Tep/Postulaciones");
    },
  },
};
</script>
