var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 table-responsive"},[_c('table',{staticClass:"table table-bordered table-striped table-hover text-nowrap table-sm"},[_c('thead',[_c('tr',{staticClass:"text-center"},[_c('th',[_vm._v("Acción")]),_c('th',[_vm._v("Tipo Vehículo")]),_c('th',[_vm._v("Tipo Operación")]),_c('th',[_vm._v("Turno")]),_c('th',[_vm._v("Vehículo")]),_c('th',[_vm._v("Conductor")]),(
                _vm.postulaciones[0] &&
                _vm.postulaciones[0].tipo_operacion != 3 &&
                _vm.postulaciones[0].turno.dia_turno[0].cant_horas > 12
              )?_c('th',[_vm._v("Conductor 2")]):_vm._e(),_c('th',[_vm._v("Fecha")])])]),_vm._l((_vm.content),function(postu,index){return _c('tbody',{key:index},[_c('tr',[_c('td',[(postu.estado == 1)?_c('div',{staticClass:"btn-group"},[(_vm.$route.params.accion == 1 &&
                    _vm.$store.getters.can('tep.postulaciones.editRestDay') &&
                    postu.fecha >= _vm.hoy &&
                    _vm.selectVehi != postu.indexPost + '_' + postu.indexDetPost
                  )?_c('button',{staticClass:"btn btn-default btn-sm bg-navy",on:{"click":function($event){return _vm.editDetPost(postu.indexPost, postu.indexDetPost)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(
                    _vm.form1[index].fecha >= _vm.hoy &&
                    _vm.selectVehi == postu.indexPost + '_' + postu.indexDetPost
                  )?_c('button',{staticClass:"btn btn-default btn-sm bg-primary",on:{"click":function($event){return _vm.saveDetPost(index, postu.tipo_operacion_id)}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e()]):_vm._e()]),_c('td',[_vm._v(_vm._s(postu.tipo_vehiculo))]),_c('td',[_vm._v(" "+_vm._s(postu.tipo_operacion)+" "),_c('strong',[_vm._v(_vm._s(postu.area ? `(${postu.area})` : ""))])]),_c('td',[_vm._v(_vm._s(postu.turno))]),_c('td',[(_vm.form1[index].vehiculo)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form1[index].vehiculo.placa),expression:"form1[index].vehiculo.placa"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","label":"placa","id":`slct_vehiculo_u${postu.indexPost} ${postu.indexDetPost}`,"disabled":_vm.selectVehi != postu.indexPost + '_' + postu.indexDetPost},domProps:{"value":(_vm.form1[index].vehiculo.placa)},on:{"change":function($event){return _vm.buscarVehiculos(index, postu.tipo_vehiculo_id)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form1[index].vehiculo, "placa", $event.target.value)}}}):_vm._e()]),_c('td',[(_vm.form1[index].conductor)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form1[index].conductor.numero_documento),expression:"form1[index].conductor.numero_documento"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","label":"numero_documento","id":`slct_conductor_u${postu.indexPost} ${postu.indexDetPost}`,"disabled":_vm.selectVehi != postu.indexPost + '_' + postu.indexDetPost},domProps:{"value":(_vm.form1[index].conductor.numero_documento)},on:{"change":function($event){return _vm.buscarConductores(index)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form1[index].conductor, "numero_documento", $event.target.value)}}}):_vm._e(),_c('div',[_c('small',[_vm._v(_vm._s(_vm.form1[index].conductor ? _vm.form1[index].conductor.nombre : ""))])])]),(
                _vm.postulaciones[0] &&
                _vm.postulaciones[0].tipo_operacion != 3 &&
                _vm.postulaciones[0].turno.dia_turno[0].cant_horas > 12
              )?_c('td',[(_vm.form1[index].conductor_2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form1[index].conductor_2.numero_documento),expression:"form1[index].conductor_2.numero_documento"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","label":"numero_documento","id":`slct_conductor_2_u${postu.indexPost} ${postu.indexDetPost}`,"disabled":_vm.selectVehi != postu.indexPost + '_' + postu.indexDetPost},domProps:{"value":(_vm.form1[index].conductor_2.numero_documento)},on:{"change":function($event){return _vm.buscarConductores_2(index)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form1[index].conductor_2, "numero_documento", $event.target.value)}}}):_vm._e(),_c('div',[_c('small',[_vm._v(_vm._s(_vm.form1[index].conductor_2 ? _vm.form1[index].conductor_2.nombre : ""))])])]):_vm._e(),_c('td',[(_vm.form1[index].fecha_ver)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form1[index].fecha_ver),expression:"form1[index].fecha_ver"}],staticClass:"form-control form-control-sm",attrs:{"type":"text","label":"fecha","disabled":""},domProps:{"value":(_vm.form1[index].fecha_ver)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form1[index], "fecha_ver", $event.target.value)}}}):_vm._e()])])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }